<template>
  <div class="number-input input-group">
    <input
        class="form-control"
        :id="id"
        v-model="formatted"
        type="text"
        :disabled="isDisabled"
        @keyup="handleKeyUp"/>
    <span v-if="$compute(sign)" class="input-group-addon">{{ $compute(sign) }}</span>
  </div>
</template>

<script>
export default {
  name: "NumberInput",
  props: {
    id: {
      type: String,
      default: null
    },
    value: {
      type: Number,
      default: 0.0
    },
    sign: {
      type: [String, Function],
      default: null
    },
    disabled: {
      type: [Boolean, Function],
      default: false
    },
    min: {
      type: [Number, Function],
      default: null
    },
    max: {
      type: [Number, Function],
      default: null
    },
    precision: {
      type: [Number, Function],
      default: 0
    }
  },
  data() {
    return {
      recompute: false
    }
  },
  computed: {
    precisionMultiplicator() {
      return 1.0 / (10 ** this.$compute(this.precision))
    },
    isDisabled() {
      return typeof this.disabled === 'function' ? this.disabled() : this.disabled
    },
    formatted: {
      get: function () {
        if (this.recompute) {/* only for recompute */
        }
        return this.value.toFixed(this.$compute(this.precision))
      },
      set: function (newValue) {
        const numbersOnly = newValue.replace(/\D/g, "")
        let sign = newValue.length > 0 && newValue[0] === "-" ? "-" : "+"
        let newInt = numbersOnly.length > 0 ? this.getMinMaxed(Number(sign + numbersOnly) * this.precisionMultiplicator) : 0
        this.recompute = !this.recompute
        if (this.value !== newInt) this.$emit('input', newInt)
      }
    }
  },
  methods: {
    getMinMaxed(number) {
      if (this.$compute(this.min) != null && number < this.$compute(this.min)) return this.$compute(this.min)
      if (this.$compute(this.max) != null && number > this.$compute(this.max)) return this.$compute(this.max)
      return number
    },
    handleKeyUp(event) {
      let newValue = null
      // up
      if (event.keyCode === 38) newValue = this.getMinMaxed(this.value + this.precisionMultiplicator)
      // down
      if (event.keyCode === 40) newValue = this.getMinMaxed(this.value - this.precisionMultiplicator)
      // plus
      if (event.keyCode === 171 || event.keyCode === 107) newValue = this.getMinMaxed(Math.abs(this.value))
      // minus
      if (event.keyCode === 173 || event.keyCode === 109) newValue = this.getMinMaxed(Math.abs(this.value) * -1)
      if (newValue !== null) this.$emit('input', newValue)
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
