import Vue from "vue";
import store from "@/store";

Vue.prototype.$g = (obj, key, separator = '.') => {
    let arr = key.split(separator);
    while (arr.length && (obj = obj[arr.shift()])) ;
    return obj;
}

Vue.prototype.$s = (obj, key, value, separator = '.') => {
    let arr = key.split(separator);
    const lastKey = arr.pop()
    while (arr.length && (obj = obj[arr.shift()])) ;
    if (obj !== undefined && typeof obj === 'object') obj[lastKey] = value
    return obj;
}

Vue.prototype.$checkPermissions = (permissions, current_user = store.getters["user/current_user"] || null) => {
    if (permissions === current_user) return true
    if (current_user === null || current_user === undefined) return false
    if (permissions === undefined) return true
    let result = true
    Object.keys(permissions).forEach((k) => {
        let v = permissions[k]
        if (result) {
            if (k === 'public' && v === true) result = true
            else if (typeof v === 'object') result = Vue.prototype.$checkPermissions(v, current_user[k])
            else if (typeof v === 'function') result = v(current_user[k])
            else if (v !== current_user[k]) result = false // check for something except
        }
    })
    return result
}

Vue.prototype.$closeWindow = () => {
    window.close()
}

Vue.prototype.$parseDates = (object) => {
    const ISO_8601 = /[0-9]{4}-[0-9]{2}-[0-9]{2}T([0-9]{2}:){2}[0-9]{2}.[0-9]{3}[+|-][0-9]{2}:[0-9]{2}/
    if (typeof object === 'object' && object) {
        if (Array.isArray(object)) {
            object.forEach(o => {
                Vue.prototype.$parseDates(o)
            })
        } else {
            Object.keys(object).forEach(key => {
                if (typeof object[key] === 'string' && ISO_8601.test(object[key])) { // check for isoformat
                    const date = new Date(object[key])
                    if (!isNaN(date.getTime())) {
                        object[key] = date
                    }
                } else {
                    Vue.prototype.$parseDates(object[key])
                }
            })
        }
    }
    return object
}

Vue.prototype.$makeDialog = (context, title, body) => {
    return context.$bvModal.msgBoxConfirm(body, {
        title: title,
        size: "sm",
        buttonSize: "xs",
        okTitle: Vue.prototype.$t('button.confirm'),
        okVariant: 'danger',
        cancelTitle: Vue.prototype.$t('button.back'),
        hideHeaderClose: true,
        centered: true,
        headerClass: 'p-2 text-center', // TODO
        bodyClass: 'p-2 text-center',
        footerClass: 'p-2 text-center',
    });
}

Vue.prototype.$makeRemoveDialog = (context) => {
    return Vue.prototype.$makeDialog(context, Vue.prototype.$t("dialog.remove"), Vue.prototype.$t('dialog.are_you_sure'))
}

Vue.prototype.$hasEmailFormat = (email) => {
    return typeof email === 'string' && email.includes("@") && email.includes(".")
}

Vue.prototype.$hasPasswordFormat = (password) => {
    return typeof password === 'string' && password.length > 0
}

Vue.prototype.$tabLabel = tab => {
    return Vue.prototype.$t(tab.label) + (tab.recordsTotal >= 0 ? ' (' + tab.recordsTotal + ')' : '')
}

Vue.prototype.$compute = (value, args) => {
    return typeof value === 'function' ? value(args) : value
}

Vue.prototype.$hasUserAgentIos = () => {
    return window.navigator.userAgent.match(/iPhone/) != null || window.navigator.userAgent.match(/iPad/) != null
}

Vue.prototype.$hasUserAgentAndroid = () => {
    return window.navigator.userAgent.match(/Android/) != null
}

Vue.prototype.$hasPlatformMac = () => {
    return navigator.platform.toUpperCase().indexOf('MAC') >= 0;
}

Vue.prototype.$hasPlatformWindows = () => {
    return navigator.platform.toUpperCase().indexOf('WIN') >= 0;
}

Vue.prototype.$sha256 = async (text) => {
    // encode as UTF-8
    const msgBuffer = new TextEncoder().encode(text);
    // hash the message
    const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);
    // convert ArrayBuffer to Array
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    // convert bytes to hex string
    return hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
}

Vue.prototype.$parseFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        // Resolve with the base64 string when the file is successfully read
        reader.onload = () => {
            resolve(reader.result);
        };
        // Reject with an error if there is a problem reading the file
        reader.onerror = () => {
            reject(reader.error);
        };
        // Read the file as a data URL (base64)
        reader.readAsDataURL(file);
    });
}

Vue.prototype.$simpleFormat = (value) => {
    if (!value) return '';
    return value.replace(/\n/g, '<br>');
}

Vue.prototype.$findRouteByName = (router, name) => {
    const queue = [...router.options.routes]
    while(queue.length > 0) {
        const route = queue.shift()
        if (route.name === name)
            return route;
        else if(route.children !== undefined && route.children.length > 0) {
            queue.push(...route.children)
        }
    }
    return undefined
}

Vue.prototype.$hasRoutePermission = (router, routeName, current_user = store.getters["user/current_user"] || null) => {
    return Vue.prototype.$checkPermissions(Vue.prototype.$findRouteByName(router, routeName)?.meta?.permissions, current_user)
}

Vue.prototype.$append_tidio_script = (document) => {
    // Dynamically add Tidio script
    const tidioScript = document.createElement('script');
    tidioScript.src = '//code.tidio.co/jccc8lstrdq3n87ek7spxtmcrmsqka5t.js';
    document.body.appendChild(tidioScript);
}