<template>
  <div class="searchable-select">

    <model-list-select v-if="!multiple"
                       :list="records"
                       v-model="id"
                       :placeholder="$t(placeholder)"
                       :optionValue="optionValue"
                       :optionText="optionText"
                       @searchchange="(text) => { /* TODO: this.search = text */ }"
                       :isDisabled="isDisabled">
    </model-list-select>

    <multi-list-select v-if="multiple"
                       :list="records"
                       :selectedItems="selectedRecords"
                       :placeholder="$t(placeholder)"
                       :optionValue="optionValue"
                       :optionText="optionText"
                       :isDisabled="isDisabled"
                       @select="(options) => { this.ids = options.map(option => option.id) }">
    </multi-list-select>

  </div>
</template>

<script>
import {ModelListSelect, MultiListSelect} from 'vue-search-select'

export default {
  name: "SearchableSelect",
  components: {ModelListSelect, MultiListSelect},
  props: {
    value: {
      type: [Number, Array, String],
      default: 0,
    },
    placeholder: {
      type: String,
      default: null
    },
    fetchRecords: {
      type: [Function, Array],
      default: () => []
    },
    disabled: {
      type: [Boolean, Function],
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    optionValue: {
      type: String,
      default: 'id'
    },
    optionText: {
      type: String,
      default: 'text'
    }
  },
  data() {
    return {
      id: 0,
      ids: [],
      records: [],
      filterQuery: {
        //page: 1, // TODO: paginate
        //per_page: 10,
        search: ""
      },
      search: ""
    }
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        if (typeof this.value === 'object') this.ids = this.value
        else this.id = this.value
      }
    },
    id: {
      immediate: false,
      handler() {
        if (typeof this.id !== 'number' && typeof this.id !== 'string') return this.id = 0
        this.$emit('input', this.id)
        this.$emit('onSelected', this.records.find((record) => record.id === this.id))
      }
    },
    ids: {
      immediate: false,
      handler() {
        this.$emit('input', this.ids)
        this.$emit('onSelected', this.records.filter((record) => this.ids.includes(record.id)))
      }
    },
    filterQuery: {
      immediate: true,
      deep: true,
      async handler(oldV, newV) {
        this.busy = true
        const response = typeof this.fetchRecords === 'function' ? await this.fetchRecords(this.filterQuery) : this.fetchRecords
        this.setRecords(response)
        this.busy = false
      }
    },
    fetchRecords: {
      immediate: false,
      deep: true,
      handler() {
        if (typeof this.fetchRecords !== 'function') this.setRecords(this.fetchRecords)
      }
    }
  },
  methods: {
    setRecords(response) {
      // use content but not list reference (avoid altering)
      this.records = [].concat(response.results || response)
      // transform simple array to proper data structure
      if ((this.records?.length || 0) > 0 && typeof this.records[0] !== 'object') {
        let i = 0
        this.records = this.records.map((entry) => {
          const item = {}
          item[this.optionValue] = i += 1
          item[this.optionText] = entry
          return item
        })
      }
      // prepending empty element to list
      if (!this.multiple && typeof this.placeholder === 'string' && !this.records.find(record => record[this.optionText] === this.$t(this.placeholder))) {
        const item = {}
        item[this.optionValue] = this.records.find((record) => record.id === 0) ? -1 : 0
        item[this.optionText] = this.$t(this.placeholder)
        this.records.unshift(item);
      }
    }
  },
  computed: {
    isDisabled() {
      return typeof this.disabled === 'function' ? this.disabled() : this.disabled
    },
    selectedRecords() {
      return this.records.filter(entry => this.ids.includes(entry.id))
    }
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
