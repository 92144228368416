<template>
  <div class="currency-input input-group">
    <input
        class="form-control"
        :id="id"
        v-model="formatted"
        type="text"
        :disabled="isDisabled"/>
    <span class="input-group-addon">{{ $compute(sign) || $t('unit.euro') }}</span>
  </div>
</template>

<script>
export default {
  name: "CurrencyInput",
  props: {
    id: {
      type: String,
      default: null
    },
    value: {
      type: Number,
      default: 0
    },
    sign: {
      type: [String, Function],
      default: null
    },
    disabled: {
      type: [Boolean, Function],
      default: false
    }
  },
  data() {
    return {
      recompute: false
    }
  },
  computed: {
    isDisabled() {
      return typeof this.disabled === 'function' ? this.disabled() : this.disabled
    },
    formatted: {
      get: function () {
        if (this.recompute) {/* only for recompute */
        }
        let newValue = (this.value / 100.0)
        return newValue.toFixed(2)
      },
      set: function (newValue) {
        const numbersOnly = newValue.replace(/\D/g, "")
        let newInt = Number(numbersOnly)
        if (numbersOnly.length < 3 || numbersOnly.length < newValue.length - 1 || (newInt === 0 && newValue.length !== 4)) this.recompute = !this.recompute
        if (this.value !== newInt) this.$emit('input', newInt)
      }
    }
  },
  methods: {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
