<template>
  <b-modal v-on="$listeners"
           v-model="visible"
           :title="title"
           size="lg"
           hide-header-close
           no-close-on-backdrop
           @hide.prevent
           modal-class="accept-gtc-modal"
           header-class="justify-content-center"
           footer-class="justify-content-start"
           @cancel.prevent="logout" @ok.prevent="submitModal">

    <template #modal-title>
      <h1>{{ title }}</h1>
    </template>

    <template #default="{ ok }">
      <h3>Stand 10.2024</h3>

      <p>Flip Technologies GmbH bietet mit dem Online-Portal „prologistics.io“, folgend "Prologistics", eine digitale Plattform für die Übermittlung und
        Durchführung von Transportaufträgen auf eigener Achse an. Diese allgemeinen Nutzungsbedingungen (im folgenden
        „AGB“) regeln das Vertragsverhältnis zwischen Flip Technologies GmbH und dem Nutzer von Prologistics.</p>

      <h3>1. Definitionen</h3>
      <p>Auftraggeber ist die Flip Technologies GmbH, ansässig in der Konsul-Smidt-Str. 24, 28217 Bremen.</p>
      <p>Auftragnehmer ist der Gewerbetreibende bzw. Fahrdienstleister, welcher nach diesen Bestimmungen Fahrzeuge für
        den Auftraggeber auf eigener Achse überführt.</p>
      <p>Transporte auf eigener Achse sind Transporte, bei denen Kraftfahrzeuge (Kfz) eigenständig durch einen
        Fahrzeugführer gefahren werden.</p>

      <h3>2. Vertragsgegenstand und Leistungen</h3>
      <p><b>2.1</b> Die Flip Technologies GmbH erbringt softwaregestützte Dienstleistungen zur Übermittlung und Umsetzung von
        Transportaufträgen auf eigener Achse. Flip Technologies GmbH bietet mit prologistics.io eine digitale Plattform, auf welcher Transportaufträge für
        den Auftragnehmer angeboten werden. Der Auftragnehmer kann einzelne Transportaufträge digital buchen und die
        Transportdiensleistung durchführen.</p>
      <p><b>2.2</b> Dieser Vertrag regelt die Rechte und Pflichten der Vertragsparteien bei der Inanspruchnahme der
        Dienstleistungen von Flip Technologies GmbH für alle gegenwärtigen und zukünftigen Transportaufträge der
        Prologistics-Plattform, sofern diese nicht gegen gesetzliche Bestimmungen verstoßen.</p>

      <h3>3. Vermittlungsdiensleistungen von Flip Technologies GmbH</h3>
      <p><b>3.1</b> Flip Technologies GmbH bietet dem Auftragnehmer die Möglichkeit, sich für die Nutzung der Prologistics-Plattform zu
        registrieren. Im Rahmen der Registrierung kann der Auftragnehmer wählen, ob die durch ihn zukünftig
        durchzuführenden Transportaufträge, gemäß in den auf der Prologistics-Plattform näher dargestellten Umfang und
        Bedingungen transportiert werden oder er selbst für einen angemessenen Versicherungsschutz sorgt.</p>
      <p>Für die Registrierung muss der Auftragnehmer verschiedene Informationen bereitstellen. Erst nach erfolgreicher
        Prüfung und Vollständigkeit der Daten erfolgt die Freischaltung zur Prologistics-Plattform durch den
        Auftraggeber. Die individuelle Freischaltung steht im Ermessen des Auftraggebers, ein automatischer Anspruch des
        Auftragnehmers besteht nicht.</p>
      <p>Eine Überprüfung der vom Auftragnehmer bei der Registrierung hinterlegten Daten für den Auftraggeber erfolgt
        nur im Hinblick auf die Vollständigkeit. Der Auftraggeber überprüft nicht die vom Auftragnehmer angegebenen
        Daten auf Richtigkeit und übernimmt somit keine Haftung für Nachteile, Schäden und Kosten, die dadurch
        entstehen, dass die vom Auftragnehmer angegebenen Daten nicht korrekt sind. Accounts mit fehlerhaften Daten
        werden vom Auftraggeber gelöscht.</p>
      <p>Der Auftragnehmer ist verpflichtet sicherzustellen, dass die im Rahmen der Registrierung angegebenen Daten
        stets aktuell und zutreffend sind. Der Auftraggeber übernimmt keine Haftung für Schäden oder Verluste, die aus
        einer Verletzung dieser Pflicht durch den Auftragnehmer entstehen. Der Auftraggeber kann vom Auftragnehmer
        jederzeit eine Bestätigung der Richtigkeit seiner Angaben sowie zusätzliche Informationen, Dokumente oder andere
        Nachweise, insbesondere im Rahmen der Registrierung, verlangen, die dieser dem Auftraggeber unverzüglich
        zukommen lassen muss.</p>
      <p>Der Auftragnehmer darf die vom Auftraggeber zur Verfügung gestellten Zugangsdaten nicht an Dritte weitergeben.
        Der Auftragnehmer ist verpflichtet, den Auftraggeber unverzüglich darüber zu informieren, wenn Anhaltspunkte
        dafür bestehen, dass ein Account von Dritten missbraucht wurde.</p>
      <p>Der Auftraggeber kann den Auftragnehmer jederzeit ohne die Angabe von Gründen einseitig sperren.</p>
      <p>Nach erfolgter Registrierung bietet der Auftraggeber dem Auftragnehmer durch Nutzung der digitalen Plattform
        folgende Funktionen und Dienstleistungen an:</p>
      <ul>
        <li>Übersicht der über die Prologistics-Plattform angebotenen Transportaufträge;</li>
        <li>Die Möglichkeit zur Buchung und Durchführung von Transportaufträgen;</li>
        <li>Unterstützung bei der Ausführung der vermittelten Transportaufträge via Telefon und E-Mail-Service
          (Support), wobei kostenpflichtige Supportdienstleistungen als solche gekennzeichnet und nach ihrer
          Inanspruchnahme zu bezahlen sind;
        </li>
      </ul>
      <p><b>3.2</b> Das Leistungsangebot vom Auftraggeber beschränkt sich gegenüber dem Auftragnehmer ausschließlich auf
        die auf der Prologistics-Plattform ausgewiesenen Dienstleistungen. Der Auftragnehmer hat bei der Nutzung der
        Dienste keinerlei Anspruch gegenüber dem Auftraggeber auf das Zustandekommen eines Transportauftrages.
      </p>
      <p><b>3.3</b> Der Anspruch des Auftragnehmers auf Nutzung der Prologistics-Plattform besteht nur im Rahmen des
        aktuellen Stands der Technik. Der Auftraggeber ist berechtigt, Leistungen in angemessenem und/oder
        erforderlichem Umfang zeitweilig einzuschränken. Der Auftraggeber berücksichtigt dabei stets die berechtigte
        Interessen der Auftragnehmer.</p>

      <p>
        <b>3.4 Annahme und Abrechnung eines Transportauftrages auf der Prologistics-Plattform</b><br>
        Der Auftragnehmer erhält eine Übersicht aller aktuell angebotenen Transportaufträge. Entschließt sich der
        Auftragnehmer dazu einen Transportauftrag durchzuführen, wird dieser verpflichtend auf der
        Prologistics-Plattform (mit Klick auf „Auftrag annehmen“) dem Auftragnehmer zugewiesen. Mit Annahme eines
        Transportauftrages bestätigt der Auftragnehmer das zu diesem Zeitpunkt aktuelle Vergütungsmodel nach 3.5. Der
        Auftragnehmer verpflichtet sich den Transportauftrag mit allen dazugehörigen Rahmenbedingungen ordnungsgemäß
        durchzuführen. Hierzu zählen insbesondere die Einhaltung von Fristen und Spezifikationen für u.a. Start- und
        Zieladresse, Route und Fahrzeugtyp. Die Abhol- bzw. Abgabezeiten der zu überführenden Fahrzeuge sind in der
        Prologistics-Plattform einsehbar und richten sich nach dem earliest, bzw. latest pick up date. Das earliest pick
        up date ist der Zeitpunkt, zudem der Auftragnehmer das zu überführende Fahrzeug frühestens entgegennehmen kann.
        Das latest pick up date ist der Zeitpunkt, zu dem der Auftragnehmer das zu überführende Fahrzeug spätestens
        entgegen nehmen muss. Das Fahrzeug gilt als entgegen genommen, wenn der Auftragnehmer selbst oder ein
        Erfüllungsgehilfe das Fahrzeug nach den Kriterien in Ziffer 4 und 5 übernommen hat und den Transportauftrag
        antritt. Weitere, individuelle Spezifikationen pro Transportauftrag gilt es zu berücksichtigen und zu beachten.
        Nach erbrachter Transportdienstleistung durch den Auftragnehmer hat der Auftraggeber mit dem Zeitpunkt der
        übermittelten „Auslieferung“ durch den Auftragnehmer in der Prologistics-Plattform 48 Stunden Zeit, den Auftrag
        als abgeschlossen zu bewerten.
      </p>
      <p>
        <b>3.5 Vergütung und Abrechnung</b><br>
        Der Auftragnehmer hat einen Anspruch auf Zahlung für die erbrachte Transportdienstleistung für abgeschlossene
        Aufträge. Die Vergütung richtet sich nach dem zum Zeitpunkt der Durchführung des zum Auftrag gültigen
        Vergütungsmodell.<br>
        Die Abrechnung erfolgt via Sammelrechnung nachträglich für jeden vorigen Monat durch eine Gutschrift. Eine
        Gutschrift erfolgt für alle Transportaufträge, die durch den Auftraggeber innerhalb eines Kalendermonats bzw.
        Abrechnungszeitraumes als abgeschlossen durch den Auftraggeber gekennzeichnet wurden. Der Auftragnehmer erklärt
        sich grundsätzlich mit einer Abrechnung mittels Gutschrift einverstanden. Der Auftragnehmer erhält im Rahmen der
        erhaltenen Gutschriften über die Prologistics-Plattform separat erstellte Abrechnungen und Aufwendungsbelege.
        Gutschriften werden mit einer Frist von 30 Tagen für den jeweiligen, vorigen Monat an das angegebene Bankkonto
        des Auftragnehmers überwiesen.<br>
        Das aktuelle, zum Zeitpunkt der Auftragsannahme gültige Vergütungsmodell ist jederzeit über die prologistics.io
        Plattform abrufbar. Die Preise beziehen sich auf die reinen Transport-kosten, weitere, variable Kosten
        (Treibstoff, Maut etc.) werden bei der Aufwandsabrechnung durch den Auftragnehmer hinzugefügt und mit Abschluss
        der Fahrt über die Prologistics-App hochgeladen. Belege, die nicht im direkten Anschluss der Fahrt über die App
        hochgeladen wurden, werden abgelehnt und können nicht nachgereicht werden.
      </p>

      <h3>4. Pflichten des Auftragnehmers</h3>
      <p>
        <b>4.1 Allgemein</b><br>
        Der Auftragnehmer hat dafür Sorge zu tragen, dass er jederzeit die gesetzlichen Voraussetzungen für die
        Durchführung der erteilten Aufträge erfüllt, vor allem, aber nicht ausschließlich, dass
      </p>
      <ul>
        <li>er bzw. die von ihm zur Erfüllung seiner Verpflichtungen eingesetzten Personen über die für das Betreiben
          des Geschäftes und für die Erfüllung dieser Vereinbarung notwendigen behördlichen Genehmigungen verfügen;
        </li>
        <li>er bzw. die von ihm zur Erfüllung seiner Verpflichtungen eingesetzten Personen stets über die für die
          Fahrzeugüberführung in den Ländern, in denen sie zur Erfüllung des jeweiligen Einzelauftrags ein Kraftfahrzeug
          führen, erforderliche gültige Fahrerlaubnis verfügen und diese im Original mitführen;
        </li>
        <li>ausländische Fahrer, soweit erforderlich, im Besitz einer gültigen Arbeits- und Aufenthaltserlaubnis für die
          Länder sind, in denen sie zur Erfüllung des jeweiligen Einzelauftrages ein Kraftfahrzeug führen und diese
          Unterlagen im Original mitführen;
        </li>
        <li>die vereinbarten Ziele auf direktem und zeitlich kürzestem Wege angefahren werden;</li>
        <li>während der Überführungsfahrten keine Personen befördert werden.</li>
      </ul>
      <p>Bei Abholung eines Fahrzeugs werden die Personalien des mit der Überführung beauftragten Fahrers in einen
        aufgenommen, den der Fahrer vor Ort zu unterzeichnen hat.</p>

      <p>
        <b>4.2 Pflichten des Auftragnehmers zur Nutzung der digitalen Protokoll-App von Prologistics</b><br>
        Um die Abläufe, insbesondere die Prüfung, ob es sich bei der jeweiligen bei Fahrzeugabholung vorstellig
        werdenden Person um eine von dem Auftragnehmer zur Erfüllung seiner Verpflichtungen eingesetzte Person handelt,
        effizient und sicher zu gestalten, stellt Flip Technologies GmbH dem Auftragnehmer einen Zugang zu einer digitalen
        Protokollierung-Applikation zur Verfügung. Nach der Registrierung können die Fahrer sich mit der App ausweisen
        und so sicherstellen, dass Sie vom Auftragnehmer mit der Abholung beauftragt wurden.
      </p>
      <p>Der Auftragnehmer hat dafür Sorge zu tragen,</p>
      <p>
        (i) dass er nur denjenigen Personen, die er zur Erfüllung seiner Verpflichtungen einsetzt und die die
        Anforderungen aus dieser Rahmenvereinbarung erfüllen, eine Einladung zur Registrierung über die
        Prologistics-Plattform zugeteilt wird.<br>
        (ii) dass er diejenigen Personen, denen er eine Einladung aus der Prologistics-Plattform zuteilt, über den
        sorgsamen Umgang mit dem Zugang unterrichtet, insbesondere darüber, dass die Protokoll App ausschließlich zur
        Identifizierung und Protokollierung des Fahrzeugzustandes im Rahmen eines durch Flip Technologies GmbH an den Auftragnehmer
        erteilten Auftrages verwendet werden dürfen.<br>
        (iii) dass er spätestens mit Beendigung des Arbeitsvertrages zwischen dem Auftragnehmer und derjenigen Personen,
        der er einen Zugang ausgehändigt hat, den Zugriff auf die Protokoll-App zu sperren.<br>
        (iv) dass jeder Verlust, jede Entwendung oder sonstiges Abhandenkommen sowie jeder Missbrauch der
        Prologistics-Plattform oder der Protokoll-App unverzüglich an den Auftraggeber anzeigen wird. Die vorstehende
        Verpflichtung gilt entsprechend, wenn Anhaltspunkte für eine missbräuchliche Verwendung bestehen.<br>
        (v) dass er seine Arbeitsabläufe so gestalten wird, dass er nachweisen kann, wer zum jeweiligen<br>
        Zeitpunkt Inhaber einer Protokoll-App war. Mit Beendigung dieses Vertrages dürfen sämtliche Zugänge zur
        Prologistics-Plattform oder der Protokoll-App nicht mehr verwendet werden.
      </p>


      <h3>5. Durchführung des Transportauftrages</h3>
      <p>
        <b>5.1 Abholung und Abgabe des Fahrzeuges</b><br>
        Der Auftragnehmer hat die vorgegebenen Prozesse des Auftraggebers für eine ordnungsgemäße Übergabe bei Abholung
        und Übergabe des Fahrzeuges einzuhalten. Hierzu zählen insbesondere die Einhaltung des vereinbarten Abhol- bzw.
        Abagbezeitpunktes und die Vollständigkeit und Richtigkeit der erforderlichen Dokumente. Die Protokollierung des
        Fahrzeugzustandes zum Zeitpunkt der Fahrzeugübergabe bzw. Rücknahme versteht sich als zeitpunktbezogene
        Dokumentation des Gefahrüberganges an den Auftragnehmer bei Abholung bzw. auf den Auftraggeber bei Abgabe.
        Hierfür ist die Nutzung der mobilen Prologistics App zwingend zu beachten. Fahrzeugdaten, Schadensprotokoll sowie die
        Bestätigung der pünktlichen und ordnungsgemäßen Übergabe vor Fahrtantritt werden von beiden Parteien via mobiler
        App dokumentiert. Die Parteien bestätigen mit ihrer Unterschrift den entsprechenden Fahrzeugzustand. Der
        Auftragnehmer sichert eine sorgfältige Bearbeitung zu und übernimmt die Haftung für nicht dokumentierte Schäden.
      </p>
      <p>Verzögert sich die Abgabe des Fahrzeuges aufgrund von Umständen, die weder der Auftragnehmer oder der
        Auftraggeber zu verschulden haben (u.a. durch Unfälle, Vollsperrungen etc.) und findet der Auftragnehmer bei
        Abgabe des Fahrzeuges am Zielort keine Kontaktperson zur Übergabe aufgrund von z.B. Öffnungszeiten vor, erfolgt
        der Gefahrenübergang erst zu dem Zeitpunkt, an dem der Auftraggeber das Fahrzeug begutachtet hat und das
        Fahrzeug offiziell übergeben wurde.</p>

      <p>
        <b>5.2 Transport: Weg, Tank, Unfall, Panne, Winterliche Straßenverhältnisse</b><br>
        Der Auftragnehmer hat das Fahrzeug auf direktem Weg zu überführen. Hierzu muss die Fahrt in der mobilen Prologistics
        App gestartet und das integrierte Navigationssystem genutzt werden. Es wird stets der schnellste Weg mit
        dynamischen Verkehrsdaten errechnet, dem Folge zu leisten ist. Umwege, die mehr als 10% von der dynamisch
        errechneten Gesamtkilometerzahl abweichen oder eine verzögerte Abgabe, die mehr als 60 Minuten nach errechnetem
        Ankunftszeitpunkt erfolgt, und die nicht Ursache von Behinderungen im Straßenverkehr durch Baustellen,
        Umleitungen, Sperrungen oder Unfällen sind werden nicht gestattet und stellen ein bewusstes Fehlverhalten des
        Auftragnehmers nach Ziffer 5 dar.<br>
        Der Auftragnehmer hat die Tankregelung, die im offerierten Transportauftrag in der Prologistics-Plattform
        spezifiziert ist, zu befolgen. Wird die Tankregelung missachtet und entstehen hieraus Kosten für u.a. eine
        Nachbetankung am Zielort, hat der Auftraggeber das Recht, die entstehenden Kosten ggü. dem Auftragnehmer geltend
        zu machen.<br>
        Der Auftragnehmer bzw. die von ihm beauftragten Erfüllungsgehilfen verpflichten sich für den Falle eines
        Unfalls, die Polizei zu verständigen und eine korrekte Abwicklung der dem Auftraggeber zustehenden Ansprüche aus
        dem Unfallgeschehen gegenüber der haftenden Versicherung abzuwickeln. Der Auftragnehmer sowie seine
        Erfüllungsgehilfen verpflichten sich, auch bei geringfügigen Schäden ausführlich schriftlich über den
        Unfallhergang zu berichten.<br>
        Der Auftragnehmer verpflichtet sich gegenüber dem Auftraggeber, in einem Schadensfall sämtliche Informations-
        und Mitwirkungspflichten fristgerecht gegenüber der Versicherung zu erfüllen. Bei Verletzung von Obliegenheiten
        gegenüber der Versicherung, die zu einer Ablehnung der Ersatzpflicht der Versicherung führt, kann der
        Auftraggeber Schadenersatzansprüche ggü. dem Auftragnehmer geltend machen.<br>
        Für den Fall einer Panne ist der Auftragnehmer berechtigt, die üblichen Pannenhilfen in Anspruch zu nehmen und
        das Fahrzeug notwendigenfalls abschleppen zu lassen. Für diesen Fall wird der Auftragnehmer sich umgehend mit
        dem Auftraggeber in Verbindung setzen und mit diesem die weitere Vorgehensweise absprechen.<br>
        Bei winterlichen Straßenverhältnissen ist der Auftragnehmer berechtigt, die Überführung von Fahrzeugen, die
        nicht mit geeigneten Reifen ausgestattet sind, abzulehnen bzw. begonnene Überführungsfahrten bei Eintritt
        winterlicher Straßenverhältnisse zu unterbrechen. Die Bereitstellung des Fahrzeugs mit Witterungsbedingter
        Bereifung (gem. § 2 IIIa StVO) obliegt dem Auftraggeber. Ist ein Fahrzeug nicht mit witterungsbedingter
        Bereifung ausgestattet, ohne dass dies im Rahmen der Beauftragung seitens des Auftraggebers angegeben wurde, so
        hat der Auftragnehmer das Recht, den Transportauftrag nicht anzutreten bzw. abzulehnen.<br>
        Werden Prozesse und Regelungen nach Ziffer 4 und 5 nachweislich missachtet und entstehen hierdurch Schäden und
        Kosten, die der Auftragnehmer zu verantworten hat, ist der Auftraggeber berechtigt, die entstehenden Kosten ggü.
        dem Auftraggeber geltend zu machen.
      </p>

      <h3>6. Vertragslaufzeit und Kündigung, Unzulässige Aktivitäten</h3>
      <p><b>6.1</b> Mit erfolgreicher Registrierung stimmt der Auftragnehmer den jeweils aktuellen AGB der
        Prologistics-Plattform von Flip Technologies GmbH zu. Flip Technologies GmbH behält sich das Recht vor, die AGB
        jederzeit zu ändern.
        Flip Technologies GmbH wird die Nutzer über die Änderungen und Gültigkeit der AGB dementsprechend via Mail oder
        über die Prologistics-Plattform zu informieren. Die Änderungen gelten als genehmigt und mit Inkrafttreten für ein
        bestehendes Vertragsverhältnis als verpflichtend, wenn der Auftragnehmer den aktualisierten AGBs zugestimmt hat.
        Mit der Registrierung bestätigt der Auftragnehmer, dass er in Ausübung einer gewerblichen Tätigkeit handelt. Der
        Auftragnehmer hat dem Auftraggeber unverzüglich mitzuteilen, wenn die gewerbliche berufliche Tätigkeit nicht
        mehr ausgeübt wird. In diesem Fall steht dem Auftraggeber ein außerordentliches Kündigungsrecht zu.</p>
      <p><b>6.2</b> Der Vertrag kann beidseitig mit einer Frist von 3 Monaten gekündigt werden. Das Recht zu Kündigung
        aus wichtigem Grund bleibt unberührt. Ein wichtiger Grund, der den Auftraggeber zur fristlosen Kündigung dieses
        Dienstleistungsvertrages berechtigt, liegt insbesondere vor, wenn der Auftragnehmer die Dienste für
        missbräuchliche Zwecke oder für sitten- bzw. rechtswidrige Handlungen und Inhalte nutzt. Im Falle einer
        außerordentlichen Kündigung, insbesondere einer solchen aufgrund fehlender Zuverlässigkeit bei der Durchführung
        von Transportaufträgen, hat der Auftraggeber das Recht, einzelne oder sämtliche ausstehende Transportaufträge
        des betreffenden Auftragnehmers zu stornieren oder an andere Auftragnehmer zu vergeben. Der Auftraggeber wird im
        Falle einer Kündigung aus wichtigem Grund den Auftragnehmer über die Stornierung vergebener Aufträge
        informieren. Ein Vergütungsanspruch des Auftragnehmers für stornierte Aufträge durch den Auftraggeber besteht
        nicht.</p>
      <p><b>6.3</b> Alle Kündigungen haben in Textform zu erfolgen.</p>
      <p><b>6.4</b> Nach Beendigung dieses Vertrages kann der Auftragnehmer die Dienste nicht mehr in Anspruch
        nehmen. Bei bereits laufenden Transportaufträgen können die Dienste vom Auftragnehmer hierfür noch bis
        zur Beendigung des Transportauftrages genutzt werden.</p>
      <p><b>6.5</b> Unangemessenes Verhalten und Unzuverlässigkeit stellen einen Grund zur außerordentlichen Kündigung
        dar. Deshalb behält sich der Auftraggeber vor, die Nutzung der Dienste einzuschränken oder den Account
        des Auftragnehmers zu sperren. Berechtigte Zweifel an der Zuverlässigkeit eines Auftragnehmers besteht
        insbesondere dann, wenn der Auftraggeber in nachvollziehbarer Weise die Abwicklung eines oder mehrerer
        Transportaufträge durch einen Auftragnehmer aufgrund der Verletzung branchenüblicher (Verhaltens-) Standards bei
        der Ausführung der Transportaufträge reklamiert. Reklamationen erfolgen u.a. in den folgenden Situationen:</p>
      <ul>
        <li>Nichterscheinen des Auftragnehmers (weder in eigener Person noch eines gestellten Ersatzfahrers) trotz
          Beauftragung (No-Shows)
        </li>
        <li>Fahrzeug wurde nicht auf direktem Weg überführt u./o. über Nacht behalten</li>
        <li>Behinderung einer ordnungsgemäßen Schadensabwicklung durch Außerachtlassung von Sorgfaltspflichten</li>
        <li>Mobile Prologistics App wird zur Protokollierung und Übergabe des Fahrzeugzustandes nicht genutzt</li>
        <li>Fahrten werden nicht mit der mobilen Prologistics App absolviert („getrackt")</li>
        <li>Wiederholte Unpünktlichkeit</li>
        <li>Wiederholtes Überschreiten der vereinbarten Kilometer, i.e. Mehrkilometer</li>
        <li>Mehrkilometer ohne sachliche Begründung</li>
        <li>Der Auftragnehmer storniert mehr als 5% der durch ihn angenommenen Transportaufträge</li>
        <li>Bewusste Falschangaben bzw. keine Angaben von Kennzeichen, Uhrzeiten und Kilometern ggü. dem Auftraggeber
        </li>
        <li>Mangelnde Erreichbarkeit des Auftragnehmers für den Auftraggeber vor, währen und nach der Durchführung von
          Aufträgen
        </li>
        <li>Wiederholtes Vergessen der Mitnahme von Auftragsbestätigungen; Weigerungshaltung beim erforderlichen
          Ausfüllen von Protokollen
        </li>
        <li>Nichteinhaltung von Abrechnungsfristen</li>
        <li>Unterlassen der Betankung des Überführungsfahrzeuges (wenn vereinbart) u./o. wiederholte Versäumnisse in
          diesem Zusammenhang
        </li>
        <li>Wiederholte Inrechnungsstellung von Zusatzkosten, für die der Auftraggeber nicht aufkommen muss</li>
      </ul>
      <p>Der Auftraggeber hat das Recht, die aus den Reklamationen entstanden Kosten, sofern vom Auftragnehmer
        verursacht, geltend zu machen. Die jeweiligen Kosten sind im Prologistics-Portal einzusehen oder werden individuell
        in Höhe des aufgekommenen Schadens in Rechnung gestellt.</p>

      <p><b>6.6</b> Die Inhalte und Informationen der Prologistics Plattform (unter anderem Meldungen, Daten, Informationen,
        Text, Fotos, Grafiken, Videos, Karten, Symbole, Software, Code oder anderes Material) sowie die für die
        Bereitstellung dieser Inhalte und Informationen verwendete Infrastruktur stehen im Eigentum vom Auftraggeber.
        Der Auftragnehmer stimmt zu, dass er keine von der oder über die Plattform erhaltenen Informationen,
        Softwareprogramme, Produkte oder Services ändern, kopieren, verteilen, übermitteln, anzeigen, vorführen,
        vervielfältigen, veröffentlichen, lizenzieren, übertragen, verkaufen, weiterverkaufen oder hiervon Bearbeitungen
        erstellen. Weiterhin stimmt der Auftragnehmer zu, Folgendes zu unterlassen:</p>
      <ul>
        <li>Überwachen oder Kopieren von Inhalten oder Informationen der Plattform oder Zugriff auf diese
          mithilfe von Robotern, Spidern, Scrapern oder anderen automatisierten Mitteln oder über irgendeinen manuellen
          Prozess zu irgendwelchen Zwecken ohne die ausdrückliche schriftliche Einwilligung des Auftraggebers;
        </li>
        <li>Benutzung von automatischen Ausleseprogrammen ("robots");</li>
        <li>Durchführen von Aktionen, die darauf abzielen eine unangemessene oder unverhältnismäßig hohe Belastung für
          die Infrastruktur vom Auftraggeber herbeizuführen oder eine solche zu verursachen;
        </li>
        <li>Erstellen von Deep-Links auf irgendeinen Teil zu irgendeinem Zweck ohne die ausdrückliche schriftliche
          Einwilligung vom Auftraggeber;
        </li>
        <li>Erstellen von "Frames" oder Spiegeln von einem Teil oder deren auf sonstige Weise durchgeführte Integration
          in eine andere Website ohne die vorherige schriftliche Einwilligung vom Auftraggeber oder der
        </li>
        <li>Versuch, Softwareprogramme, die vom Auftraggeberin Verbindung oder den Services verwendet werden, zu ändern,
          zu übersetzen, anzupassen, zu bearbeiten, zu dekompilieren, zu disassemblieren oder zurück zu entwickeln.
        </li>
      </ul>

      <h3>7. Haftung</h3>
      <p><b>7.1</b> Der Auftraggeber führt alle Dienste nach bestem Wissen und Gewissen durch und ist nicht für Folgen
        von Entscheidungen haftbar, die aufgrund vom Auftragnehmer übermittelter Informationen getroffen wird. Die
        Haftung vom Auftraggeber, gleich aus welchem Rechtsgrund, ist beschränkt auf Schäden, die der Auftraggeber, ihre
        gesetzlichen Vertreter, Erfüllungsgehilfen oder Beauftragten vorsätzlich oder grob fahrlässig oder bei der
        Verletzung von für die Erfüllung des Vertragszweckes wesentlichen Pflichten (sog. Kardinalspflichten) fahrlässig
        herbeigeführt haben und nach Ablauf einer Bearbeitungszeit von 24 Stunden ab Zugang der Sperrmeldung bei Flip Technologies
        GmbH entstehen, es sei denn, ein schuldhaftes Verhalten des Auftragnehmers hat zur Entstehung des Schadens
        beigetragen. In diesem Fall bestimmt sich die Schadensverteilung nach den Grundsätzen des Mitverschuldens gemäß
        § 254 BGB. Kardinalspflichten im Sinne dieser Norm sind insbesondere solche, deren Erfüllung die ordnungsgemäße
        Durchführung des Vertrages überhaupt erst ermöglicht und auf deren Einhaltung der Vertragspartner regelmäßig
        vertraut und vertrauen darf.</p>
      <p><b>7.2</b> Der Auftraggeber haftet auch dann, wenn er, seine Vertreter oder Erfüllungsgehilfen schuldhaft das
        Leben, den Körper oder die Gesundheit des Dienstleisters oder einer Person verursacht haben, die in den
        Schutzbereich des Vertrages mit einbezogen ist.</p>
      <p><b>7.3</b> Der Auftragnehmer haftet für eine ordnungsgemäße Abwicklung des Vertrages. Handlungen seiner Fahrer,
        anderer Personen und Unternehmen hat er in gleichem Umfang zu vertreten wie eigene. Der Auftragnehmer haftet
        ferner gegenüber Flip Technologies GmbH für jeglichen Missbrauch der Überführungsfahrten, insbesondere, wenn Fahrzeuge zu
        privaten Zwecken oder nach Ausscheiden eines Mitarbeiters genutzt werden.</p>
      <p><b>7.4</b> Der Auftragnehmer haftet für jegliche Schäden an Fahrzeugen sowie jegliche Fälle des Untergangs von
        Fahrzeugen, die sich in der Zeit zwischen Übernahme eines Fahrzeugs am Ort der Abholung und dessen Abgabe am Ort
        der Abgabe ereignen. Beträge, die Flip Technologies GmbH als Ersatz von Dritten erhält, sind auf Ansprüche von Flip
        Technologies GmbH gegen den Auftragnehmer anzurechnen.</p>

      <h3>8. Datenschutz und Datensicherheit</h3>
      <p><b>8.1</b> Beide Vertragsparteien werden im Rahmen der Abwicklung der Verträge die jeweils anwendbaren,
        insbesondere die in Deutschland gültigen datenschutzrechtlichen Bestimmungen und die des Telemediengesetzes
        beachten und ihre im Zusammenhang mit dem Vertrag eingesetzten Beschäftigten auf die Einhaltung der
        datenschutzrechtlichen Anforderungen nach der DSGVO verpflichten, soweit diese nicht bereits allgemein
        entsprechend verpflichtet sind. Ohne Einwilligung des Auftragnehmers wird der Auftraggeber Bestands- und
        Nutzungsdaten des Auftragnehmers nur erheben, verarbeiten oder nutzen, soweit dies für die Abwicklung des
        Vertragsverhältnisses erforderlich ist.</p>
      <p><b>8.2</b> Ohne die Einwilligung des Auftragnehmers wird der Auftraggeber Daten des Auftragnehmers nicht für
        Zwecke der Werbung, Markt- oder Meinungsforschung nutzen.</p>
      <p><b>8.3</b> Der Auftragnehmer hat jederzeit die Möglichkeit, die über ihn gespeicherten Daten abzurufen, diese
        zu ändern oder vom Auftraggeber löschen zu lassen. Das Recht zur Löschung gilt nicht, soweit (i) die Daten für
        gesetzlich zulässige Zwecke verarbeitet werden dürfen und (ii) der Auftraggeber nach Sperrung des Accounts eines
        Auftragnehmers aus wichtigem Grund gemäß vorstehend Ziff. 6 die entsprechenden Daten zwecks Individualisierung
        des Account-Inhabers zwingend benötigt, um zukünftige Registrierungen durch den gesperrten Auftragnehmer zu
        verhindern (Führen einer sog. Black List). Im Übrigen wird in Bezug auf Einwilligungen des Auftragenehmers und
        weitere Informationen zur Datenerhebung, -verarbeitung und -nutzung auf die gesonderte, vom Auftragnehmer bei
        seiner Registrierung zu akzeptierende Datenschutzerklärung verwiesen, die jederzeit auf der Prologistics Plattform
        für den Auftragnehmer abrufbar ist.</p>
      <p><b>8.4</b> Der Auftragnehmer steht dafür ein, dass er zur Erhebung und Verarbeitung personenbezogener Daten
        selbst oder durch den Auftraggeber nach den anwendbaren, insbesondere datenschutzrechtlichen Bestimmungen
        berechtigt ist, und stellt im Falle eines Verstoßes den Auftraggeber von Ansprüchen Dritter frei.</p>

      <h3>9. Geheimhaltung</h3>
      <p><b>9.1</b> Beide Vertragsparteien behandeln alle Informationen und Unterlagen, die ihnen von der jeweils
        anderen Partei zugehen oder bekanntwerden sowie den Inhalt dieses Vertrages strikt vertraulich, zumindest mit
        derselben Sorgfalt wie eigene Informationen gleicher Art. Diese Pflichten bleiben auch nach Vertragsbeendigung
        auf unbestimmte Dauer in Kraft. Beide Vertragsparteien sind verpflichtet, alle Verkörperungen der von der
        jeweils anderen Vertragspartei erteilten vertraulichen Informationen und alle auf der Grundlage solcher
        Informationen erstellten Unterlagen, Analysen, Studien, Bewertungen, Notizen u. ä. soweit tatsächlich möglich zu
        vernichten. In Datenverarbeitungsanlagen gespeicherte Daten werden nach Vertragsbeendigung auf Verlangen des
        Auftragnehmers anonymisiert. Der Auftraggeber und der Auftragnehmer werden angemessene Vorkehrungen treffen, um
        einen Zugriff unbeteiligter Dritter auf diese Informationen oder Unterlagen zu verhindern. Eine Vervielfältigung
        u./o. Weitergabe überlassener Unterlagen ist lediglich für die unmittelbare Zusammenarbeit erlaubt.</p>
      <p><b>9.2</b> Informationen, Unterlagen sowie dieser Rahmenvertrag dürfen nur für Zwecke der Durchführung des
        zwischen dem Auftraggeber und dem Auftragnehmer zustande kommenden Vertrages eingesetzt werden. Sie dürfen nur
        an solche Mitarbeiter und Subunternehmen weitergegeben werden, die sie zur Durchführung des Vertrages kennen
        müssen. Mitarbeiter und Subunternehmen sind schriftlich unmittelbar zugunsten der Parteien zur Geheimhaltung
        nach diesen Regeln zu verpflichten. Die jeweilige Partei kann eine Kopie der Verpflichtungserklärung verlangen.
      </p>
      <p><b>9.3</b> Die Geheimhaltungspflicht gilt nicht für Informationen und Unterlagen, die offenkundig sind oder
        werden, ohne dass dies auf einem Vertragsverstoß der Parteien beruht, oder die die empfangende Partei von
        Dritten erhalten hat, die befugt sind, sie der Allgemeinheit zu offenbaren. Wer sich auf diese Ausnahmen beruft,
        trägt die Beweislast.</p>

      <h3>10. Kommunikation</h3>
      <p><b>10.1</b> Der Auftragnehmer erklärt sich damit einverstanden, dass der Auftraggeber ihm gesetzlich
        vorgeschriebene Veröffentlichungen, Benachrichtigungen, Rechnungen sowie sonstige Informationen über die
        Dienste und Informationen im Zusammenhang mit diesem Vertrag durch Übersendung einer E-Mail an die vom
        Auftragnehmer angegebene E-Mail-Adresse mitteilen kann. Der Auftragnehmer erklärt sich außerdem damit
        einverstanden, dass elektronische Benachrichtigungen die gleiche Bedeutung und Wirkung haben wie auf Papier
        gedruckte Kopien. Solche Benachrichtigungen gelten 24 Stunden nach Übersendung der E-Mail als zugegangen, es sei
        denn der Auftraggeber erhält Nachricht darüber, dass die E-Mail nicht zugegangen ist.</p>
      <p><b>10.2</b> Außerdem erklärt sich der Auftragnehmer damit einverstanden, dass der Auftraggeber für eine
        effektive Bearbeitung der Registrierung des Auftragnehmers sowie der weiteren Zusammenarbeit mit dem
        Auftragnehmer im Rahmen der Inanspruchnahme der Dienste (z.B. Rechnungslegung) telefonisch Kontakt zu
        dem Auftragnehmer aufnimmt.</p>
      <p><b>10.3</b> Aus diesem Grund ist der Auftragnehmer verpflichtet, dafür zu sorgen, dass jederzeit zumindest eine
        aktuelle und gültige E-Mail-Adresse und Telefonnummer beim Auftraggeber hinterlegt sind. Der Auftraggeber
        übernimmt keine Haftung für den Fall, dass die einzige von dem Auftragnehmer angegebene E-Mail-Adresse bzw.
        Telefonnummer ungültig ist oder er die E-Mail-Adresse bzw. Telefonnummer geändert hat, ohne den Auftraggeber
        hierüber zu informieren.</p>

      <h3>11. Einhaltung arbeits-/sozialversicherungsrechtlicher Standards</h3>
      <p>Die Integrität der auf der Prologistics Plattform registrierten Auftragnehmer ist erklärtes Ziel vom Auftraggeber.
        Insofern legt der Auftraggeber gesteigerten Wert darauf Transportaufträge nur an solche Auftragnehmer zu
        vermitteln, die eine sozial- und gesetzeskonforme Unternehmensorganisation pflegen. Vor diesem Hintergrund
        verpflichten sich Auftragnehmer zur Einhaltung der gesetzlichen Bestimmungen zum Schutz von Arbeitnehmern.
        Insbesondere sichert ein Dienstleister mit Annahme eines Transportauftrages folgendes zu:</p>

      <p>
        <b>11.1 MiLoG</b><br>
        Der Auftragnehmer zahlt seinen Arbeitnehmern den für die jeweilige Branche gesetzlich geltenden Mindestlohn.
        Dies gilt auch für Leiharbeitnehmer. Der Auftragnehmer hält sämtliche Vorgaben des Gesetzes zur Regelung eines
        allgemeinen Mindestlohns (Mindestlohngesetz - MiLoG) in seiner jeweils gültigen Fassung ein und sichert zu, nur
        Nachunternehmer einzusetzen, die den Mindestlohn zahlen und vertraglich zusichern, dass der Mindestlohn auch von
        evtl. eingeschalteten Nachunternehmern gezahlt wird und diese zudem im Nachunternehmervertrag hierzu
        verpflichtet werden.
      </p>

      <p>
        <b>11.2 Sozialversicherung</b><br>
        Der Auftragnehmer kommt seinen Beitragsverpflichtungen gegenüber den Sozialversicherungsträgern ordnungsgemäß
        nach und setzt nur Nachunternehmer ein, die ihm gegenüber die ordnungsgemäße Abführung von
        Sozialversicherungsbeiträgen vertraglich zusichern.
      </p>

      <h3>12. Exklusivität</h3>
      <p>Der Auftragnehmer willigt ein, dass dem Auftraggeber eine Exklusivität gegenüber allen gewerblichen Plattformen
        und Anbietern, die direkt oder indirekt in der Vermittlung von Transportaufträgen auf eigener Achse agieren und
        die Dienste ggü. privaten Nutzern z.B. via Internetplattform oder mobiler App anbieten, eingeräumt wird.</p>

      <h3>13. Schlussbestimmungen</h3>
      <p><b>13.1</b> Änderungen dieses Vertrages bedürfen zu ihrer Wirksamkeit der Textform (§ 126 b BGB). Dies gilt
        auch für die Änderung oder Aufhebung dieser Klausel.</p>
      <p><b>13.2</b> Der Auftraggeber ist berechtigt, Marken und sonstige Kennzeichen des Auftragnehmers für die
        Vertragsdauer und im Rahmen der Erbringung der vereinbarten Leistungen zu verwenden.</p>
      <p><b>13.3</b> Der Auftraggeber ist berechtigt, alle Rechte nach diesen Bedingungen an Dritte zu übertragen. Der
        Auftragnehmer erklärt sich bereits jetzt mit der Abtretung einverstanden. Seine Rechte oder Pflichten nach
        diesem Vertrag darf der Auftragnehmer nur nach vorheriger schriftlicher Zustimmung vom Auftraggeber an Dritte
        abtreten.</p>
      <p><b>13.4</b> Für die vertraglichen Beziehungen gilt deutsches Recht. Gerichtsstand ist, soweit rechtlich
        zulässig, Bremen.</p>
      <p><b>13.5</b> Allgemeine Geschäftsbedingungen des Auftragnehmers sowie vorab geschlossene Dienstlieistungs- oder
        Rahmenverträge finden keine Anwendung.</p>
      <p><b>13.6</b> Sollten einzelne Bestimmungen dieses Vertrages unwirksam sein, berührt dies die Gültigkeit der
        übrigen Bestimmungen grundsätzlich nicht. Die Vertragsparteien werden sich in diesem Falle bemühen, anstelle der
        unwirksamen Bestimmung eine solche zu finden, die dem Vertragsziel rechtlich und wirtschaftlich am ehesten
        gerecht wird.</p>
    </template>

    <template #modal-footer="{ ok, cancel }">
      <b-row class="full-width">
        <b-col>
          <generic-input type="checkbox" v-model="acceptedGTC"
                         label="Ich habe die AGBs gelesen und bin mit deren Geltung einverstanden"></generic-input>
        </b-col>
        <b-col class="justify-content-end" align="right">
          <b-button size="sm"
                    variant="default"
                    @click="cancel">
            {{ $t('common.logout') }}
          </b-button>
          <b-button size="sm"
                    variant="primary"
                    @click="ok"
                    :disabled="!acceptedGTC">
            {{ $t('button.save') }}
          </b-button>
        </b-col>
      </b-row>
    </template>

  </b-modal>
</template>

<script>
import {logout, patchEmployeeAcceptGTC} from "@/services/employees";
import GenericInput from "@/components/generic_input.vue";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "AcceptGtcModal",
  components: {GenericInput},
  props: {
    value: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      visible: false,
      title: "Allgemeine Geschäftsbedingungen für Fahrzeugüberführungen Prologistics",
      acceptedGTC: false
    }
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        this.visible = this.value
      }
    },
    visible: {
      immediate: true,
      handler() {
        this.$emit('input', this.visible)
      }
    }
  },
  computed: {
    ...mapGetters("user", ["current_user"]),
  },
  methods: {
    ...mapActions("user", ["setUser", "resetUser"]),
    logout(event) {
      logout().then(response => {
        this.resetUser()
        window.location.href = "/"
      })
    },
    async submitModal() {
      patchEmployeeAcceptGTC(this.current_user.id, {}).then((response) => {
        this.setUser(response)
        this.visible = false
      }).catch()
    }
  }
}
</script>

<!-- Cannot be scoped -->
<style lang="scss">
.accept-gtc-modal {
  .modal-content {
    height: 85%;
  }

  .modal-body {
    overflow-y: auto;
    scrollbar-width: auto;
  }
}
</style>