<template>
  <div class="datepicker">

    <b-row>
      <b-col v-if="type !== 'timepicker'">
        <b-form-group>
          <template slot="label">
            <h5>{{ $t(getLabel(0)) + (required && !$compute(disabled) ? '' : ' (Optional)') }}</h5>
          </template>
          <b-form-datepicker
              v-model="day"
              locale="de"
              :date-format-options="{ day: '2-digit', month: '2-digit', year: 'numeric' }"
              start-weekday="1"
              :min="minDay"
              :max="maxDay"
              placeholder="DD.MM.YYYY"
              size="sm"
              :hide-header="true"
              label-help=""
              :disabled="$compute(disabled)"
              :requiredMessage="requiredMessage || $t('errors.messages.required')"
              oninvalid="setCustomValidity(attributes?.requiredMessage?.value)"
              oninput="setCustomValidity('')">
            <template slot="button-content">
              <font-awesome-icon icon="calendar"/>
            </template>
          </b-form-datepicker>
        </b-form-group>
      </b-col>
      <b-col v-if="type !== 'daypicker'">
        <b-form-group>
          <template slot="label">
            <h5>{{ $t(getLabel(1)) + (required && !$compute(disabled) ? '' : ' (Optional)') }}</h5>
          </template>
          <b-form-timepicker
              v-model="time"
              locale="de"
              placeholder="HH:MM"
              size="sm"
              :no-close-button="true"
              :hide-header="true"
              minutes-step="5"
              :disabled="$compute(disabled)"
              :requiredMessage="requiredMessage || $t('errors.messages.required')"
              oninvalid="setCustomValidity(attributes?.requiredMessage?.value)"
              oninput="setCustomValidity('')">
            <template slot="button-content">
              <font-awesome-icon icon="clock"/>
            </template>
          </b-form-timepicker>
        </b-form-group>
      </b-col>
    </b-row>

  </div>
</template>

<script>
export default {
  name: "Datepicker",
  props: {
    type: {
      default: undefined
    },
    label: {
      type: [Array, String],
      default: null
    },
    labelDay: {
      type: String,
      default: ""
    },
    labelTime: {
      type: String,
      default: ""
    },
    value: {
      type: [Date, String],
      default: new Date(),
    },
    min: {
      type: [Date, String],
      default: null
    },
    max: {
      type: [Date, String],
      default: null
    },
    disabled: {
      type: [Boolean, Function],
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    requiredMessage: {
      default: undefined
    }
  },
  data() {
    return {
      day: this.formatDay(new Date()),
      time: this.formatTime(new Date()),
    }
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        this.day = this.formatDay(this.value)
        this.time = this.formatTime(this.value)
      }
    },
    date: {
      immediate: true,
      handler() {
        this.$emit('input', this.date)
      }
    },
  },
  computed: {
    date() {
      return new Date(this.day + "T" + this.time)
    },
    minDay() {
      return this.formatDay(this.min)
    },
    maxDay() {
      return this.formatDay(this.max)
    },
  },
  methods: {
    formatDay(date) {
      return this.$options.filters.formatDate(date, "YYYY-MM-DD")
    },
    formatTime(date) {
      return this.$options.filters.formatDate(date, "HH:mm")
    },
    getLabel(index) {
      const l = this.$compute(this.label)
      if (typeof l === 'object') return l[index] // array
      else if (typeof l === 'string') return l
      return this.$compute(index === 0 ? this.labelDay : this.labelTime)
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
