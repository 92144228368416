<template>
  <div class="onboarding">
    <application :sidebar-link-list="allowedSidebarLinkList"></application>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Application from "@/layouts/application.vue";

export default {
  name: "Onboarding",
  components: {Application},
  data() {
    return {
      sidebarLinkList: [
        {
          to: {name: "login"},
          name: "button.to_login",
          icon: "chevron-left",
          meta: {
            noCurrentUser: true
          },
          active: true
        },
        {
          to: {name: "onboarding_bank_account"},
          name: "common.bank_account",
          meta: {
            permissions: {
              client: {
                state: 0
              }
            }
          }
        },
        {
          to: {name: "onboarding_tax_data"},
          name: "common.tax_data",
          meta: {
            permissions: {
              client: {
                state: 0
              }
            }
          },
          disabled: () => !this.$hasRoutePermission(this.$router, 'onboarding_tax_data', this.current_user)
        },
        {
          to: {name: "onboarding_good_conduct_certificate"},
          name: "common.good_conduct_certificate",
          meta: {
            permissions: {
              client: {
                state: 0
              }
            }
          },
          disabled: () => !this.$hasRoutePermission(this.$router, 'onboarding_good_conduct_certificate', this.current_user)
        },
        {
          to: {name: "onboarding_proof_of_insurance"},
          name: "common.proof_of_insurance",
          meta: {
            permissions: {
              client: {
                state: 0
              }
            }
          },
          disabled: () => !this.$hasRoutePermission(this.$router, 'onboarding_proof_of_insurance', this.current_user)
        },
        {
          to: {name: "onboarding_qundo_verification"},
          name: "common.verification",
          meta: {
            permissions: {
              client: {
                state: 0
              }
            }
          },
          disabled: () => !this.$hasRoutePermission(this.$router, 'onboarding_qundo_verification', this.current_user)
        },
        {
          to: {name: "onboarding_appointment"},
          name: "common.video_call",
          meta: {
            permissions: {
              client: {
                state: 0
              }
            }
          },
          disabled: () => !this.$hasRoutePermission(this.$router, 'onboarding_appointment', this.current_user)
        }
      ]
    }
  },
  computed: {
    ...mapGetters("user", ["current_user"]),
    allowedSidebarLinkList() {
      return this.filterList(this.sidebarLinkList)
    }
  },
  methods: {
    ...mapActions("user", ["resetUser"]),
    filterList(list) {
      const current_user = this.current_user
      return list.filter((t) => {
        if(t.meta.noCurrentUser) return current_user === null;
        return this.$checkPermissions(t.meta?.permissions, current_user)
      })
    }
  }
}
</script>

<style lang="scss">
.onboarding {
  .wrapper-content {
    max-width: 500px;
    padding-top: 55px;
    padding-bottom: 55px;
  }
}
</style>
