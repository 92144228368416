import Vue from "vue";
import Moment from "moment";

const DEFAULT_DATE_DAY_FORMAT = "DD.MM.YYYY"
const DEFAULT_DATE_TIME_FORMAT = "HH:mm"
const DEFAULT_DATE_FORMAT = DEFAULT_DATE_DAY_FORMAT + " | " + DEFAULT_DATE_TIME_FORMAT

Vue.filter('formatDateDefault', function (value) {
    if (!value) return ''
    return new Moment(value).format(DEFAULT_DATE_FORMAT)
})

Vue.filter('formatDate', function (value, format = DEFAULT_DATE_FORMAT) {
    if (!value) return ''
    return new Moment(value).format(format)
})

Vue.filter('formatDateDay', function (value) {
    if (!value) return ''
    return new Moment(value).format(DEFAULT_DATE_DAY_FORMAT)
})

Vue.filter('formatDateTime', function (value) {
    if (!value) return ''
    return new Moment(value).format(DEFAULT_DATE_TIME_FORMAT)
})

Vue.filter('formatDateRange', function (value) {
    if (!value) return ''
    if (value.length === 1 || value[0] === value.slice(-1)[0]) return new Moment(value[0]).format(DEFAULT_DATE_FORMAT)
    return new Moment(value[0]).format(DEFAULT_DATE_FORMAT) + " - " + new Moment(value.slice(-1)[0]).format(DEFAULT_DATE_TIME_FORMAT)
})

Vue.filter('formatDateRangeDay', function (value) {
    if (!value) return ''
    if (value.length === 1 || value[0] === value.slice(-1)[0]) return new Moment(value[0]).format(DEFAULT_DATE_DAY_FORMAT)
    const d1 = new Moment(value[0]).format(DEFAULT_DATE_DAY_FORMAT)
    const d2 = new Moment(value.slice(-1)[0]).format(DEFAULT_DATE_DAY_FORMAT)
    if (d1 === d2) return d1
    return d1 + " - " + d2
})

Vue.filter('formatDateRangeTime', function (value) {
    if (!value) return ''
    if (value.length === 1 || value[0] === value.slice(-1)[0]) return new Moment(value[0]).format(DEFAULT_DATE_TIME_FORMAT)
    return new Moment(value[0]).format(DEFAULT_DATE_TIME_FORMAT) + " - " + new Moment(value.slice(-1)[0]).format(DEFAULT_DATE_TIME_FORMAT)
})

Vue.filter('formatPrice', function (value) {
    if (!value) return '0'
    const result = (value / 100.0)
    return result % 1 === 0 ? result.toFixed(0) : result.toFixed(2)
})

Vue.filter('formatDistance', function (value) {
    if (!value) return '0'
    return (value / 1000.0).toFixed(0)
})

Vue.filter('formatDurationHours', function (value) {
    if (!value) return '0'
    return Math.floor(value / 3600.0).toFixed(0)
})

Vue.filter('formatDurationMinutes', function (value) {
    if (!value) return '0'
    return ((value / 60.0) % 60.0).toFixed(0)
})

Vue.filter('formatSign', function (value) {
    if (!value) return value
    return (value >= 0 ? "+" : "") + value
})

Vue.filter('formatBoolean', function (value) {
    return Vue.prototype.$t(value ? 'common.yes' : 'common.no')
})

Vue.filter('simpleFormat', function(value) {
    if (!value) return '';
    return value.replace(/\n/g, '<br>');
});