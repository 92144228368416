<template>
  <div id="wrapper" class="application" :class="{ 'md-visible-sidebar': showSidebar }">
    <!-- topnavbar -->
    <nav class="navbar navbar-static-top" role="navigation">
      <ul class="nav navbar-top-links full-width justify-content-between">
        <li>
          <b-button variant="no-outline" class="md-visible navbar-minimalize minimalize-styl-2"
                    @click="showSidebar = !showSidebar">
            <font-awesome-icon icon="bars"/>
          </b-button>
        </li>
        <li>
          <!-- contact dropdown -->
          <b-dropdown class="contact-dropdown" variant="link" right>
            <template #button-content>
              <a>
                {{ $t("common.contact") }}
                <font-awesome-icon icon="caret-down"/>
              </a>
            </template>
            <b-dropdown-item v-for="link in contactNavbarLinkList" v-if="link.href" :href="link.href">
              {{ $t(link.label) }}
            </b-dropdown-item>
          </b-dropdown>

          <!-- logout-button -->
          <a v-if="current_user" class="logout-button" @click.prevent="logout">
            {{ $t("common.logout") }}
          </a>
        </li>
      </ul>
    </nav>

    <!-- sidenavbar -->
    <nav class="navbar-default navbar-static-side" role="navigation">
      <div class="sidebar-collapse">
        <ul class="nav metismenu" id="side-menu">
          <li>
            <router-link class="nav-header" :to="{name: 'dashboard'}">
              <img class="navbar-logo" src="@/assets/images/prologistics_logo.png" alt="prologistics"/>
            </router-link>
          </li>

          <li v-for="(link,index) in sidebarLinkList"
              :class="{ 'active': isActive(link, index), 'inactive': !isActive(link, index) }">
            <router-link v-if="link.to !== undefined" :to="link.to"
                         :disabled="$compute(link.disabled)"
                         :event="!$compute(link.disabled) ? 'click' : ''"
                         :class="$compute(link.class) || {}"
                         :style="$compute(link.style) || {}"
                         @click.native.passive="showSidebar = false">
              <font-awesome-icon class="nav-icon" v-if="link.icon" :icon="link.icon"/>
              <span class="nav-label">{{ $t($compute(link.name)) }}</span>
            </router-link>
            <div v-else
                 :class="$compute(link.class) || {}"
                 :style="$compute(link.style) || {}">
              <font-awesome-icon class="nav-icon" v-if="link.icon" :icon="link.icon"/>
              <span class="nav-label">{{ $t($compute(link.name)) }}</span>
            </div>
          </li>
        </ul>

        <slot name="navbar-contact"></slot>
      </div>
    </nav>

    <!-- page content -->
    <div id="page-wrapper" @click.passive="showSidebar = false" @scroll.passive="showSidebar = false">

      <!-- router content -->
      <div class="wrapper-content">
        <router-view/>
      </div>

    </div>

    <!-- footer -->
    <div class="footer">
      <b-row>

        <b-col md order="1" order-md="0">
          <img class="footer-logo" alt="prologistics logo" src="@/assets/images/prologistics_logo.png"> |
          {{ $t("prologistics.slogan") }} &copy; {{ year }}
        </b-col>

        <b-col md order="0" order-md="1">
          <shared-links/>
        </b-col>

      </b-row>
    </div>

  </div>
</template>

<script>
import {logout} from "@/services/employees";
import {mapActions, mapGetters} from "vuex";
import SharedLinks from "@/components/shared_links";

export default {
  name: "Application",
  components: {SharedLinks},
  props: {
    sidebarLinkList: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      showSidebar: false,
      contactNavbarLinkList: [
        {
          label: this.$t('common.contact') + ":"
        },
        {
          label: 'E-Mail: ' + this.$t('prologistics.email'),
          href: 'mailto:' + this.$t('prologistics.email')
        },
        {
          label: 'Mobil: Whatsapp Support',
          href: this.$t('prologistics.whatsapp_url')
        },
      ]
    }
  },
  computed: {
    ...mapGetters("user", ["current_user"]),
    year() {
      return new Date().getFullYear()
    }
  },
  methods: {
    ...mapActions("user", ["resetUser"]),
    logout(event) {
      logout().then(response => {
        this.resetUser()
        window.location.href = "/"
      })
    },
    isActive(link, index) {
      return link.active || this.$route.name === link.to?.name// || this.sidebarLinkList.findIndex(item => item === link) <= index
    }
  }
}
</script>

<style scoped lang="scss">
@media (max-width: 768px) {
  .md-visible-sidebar {
    .logout-button, .contact-dropdown {
      display: none; // hide contact-dropdown/logout-button if sidebar is shown
    }
  }
}

.navbar-logo {
  width: 80%;
  height: unset;
}

.footer-logo {
  width: 80px;
}
</style>
