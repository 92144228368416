<template>
  <div id="app">
    <progress-spinner/>
    <router-view/>
  </div>
</template>

<script>
import ProgressSpinner from "@/components/progress_spinner";

export default {
  components: {ProgressSpinner}
}
</script>

<style lang="scss">
@import 'src/styles/base/variables.scss';

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>