<template>
  <div class="shared_links">
    <router-link v-for="link in filteredVerticalLinkList" :to="link.to">
      {{ $t(link.name) }}
    </router-link>

    <div :class="{ 'text-center': !simpleDesign, 'md-text-center': simpleDesign }" style="text-align: right">
      <hr v-if="!simpleDesign">
      <template v-for="(link, index) in horizontalLinkList">
        <span v-if="index > 0"> | </span>
        <router-link :to="link.to" target="_blank">{{ $t(link.name) }}</router-link>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "SharedLinks",
  props: {
    showLoginButton: {
      type: Boolean,
      default: false
    },
    showPasswordResetButton: {
      type: Boolean,
      default: false
    },
    showBackButton: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      verticalLinkList: [
        {
          to: {name: "login"},
          name: "button.login",
          condition: () => {
            return this.showLoginButton
          }
        },
        {
          to: {name: "password_reset"},
          name: "login.password_forgotten",
          condition: () => {
            return this.showPasswordResetButton
          }
        },
        {
          to: {name: "dashboard"},
          name: "button.back",
          condition: () => {
            return this.showBackButton
          }
        }
      ],
      horizontalLinkList: [
        {
          to: {name: "privacy"},
          name: "common.privacy_policy",
        },
        {
          to: {name: "terms"},
          name: "common.terms_of_use"
        },
        {
          to: {name: "imprint"},
          name: "common.imprint"
        }
      ]
    }
  },
  computed: {
    filteredVerticalLinkList() {
      return this.verticalLinkList.filter(function (t) {
        return t.condition()
      })
    },
    simpleDesign() {
      return this.filteredVerticalLinkList.length === 0
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
