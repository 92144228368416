const getDefaultState = () => {
    return {
        user: null
    }
}

const state = getDefaultState()

const mutations = {
    RESET_STATE: (state) => {
        Object.assign(state, getDefaultState())
    },
    SET_USER: (state, user) => {
        state.user = user
    }
}

const actions = {
    setUser({commit}, data) {
        commit('SET_USER', data)
    },

    resetUser({commit}) {
        commit('SET_USER', getDefaultState().user)
    }
}

const getters = {
    current_user: state => state.user
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}