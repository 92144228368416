import axios from 'axios'
import Vue from 'vue'
import store from '@/store'
import {EventBus} from "@/eventbus";

// helper functions
export function getPathPrefix() {
    const prefix = (store.getters["user/current_user"] || null)?.client?.role_name
    return prefix ? prefix + "/" : ''
}

export function getBaseUrl() {
    return window.location.origin + "/" // 'http://localhost:3000/'
}

export function getApiUrl() {
    return getBaseUrl().replace(":8080", ":3000") // replacing dev port
}

export function getPathWithQuery(path, filterQuery = {}) {
    const query = encodeToQueryParameters(filterQuery)
    // no query
    if (query === null || query === undefined || query === '') {
        return path
    }
    // path and query
    return path + '?' + query
}

export function encodeToQueryParameters(obj, prefix) {
    if (obj === null || obj === undefined) {
        if (prefix === null || prefix === undefined) {
            return null
        } else {
            return encodeURIComponent(prefix) + "=" + encodeURIComponent('');
        }
    }

    const str = [];
    for (const p in obj) {
        if (obj.hasOwnProperty(p)) {
            const k = prefix ? `${prefix}[${p}]` : p;
            const v = obj[p];
            str.push(
                (v !== null && typeof v === 'object') ? encodeToQueryParameters(v, k) : `${encodeURIComponent(k)}=${encodeURIComponent(v)}`
            );
        }
    }
    return str.join('&');
}

// basic setup

const httpClient = axios.create({
    baseURL: getApiUrl(),
    timeout: 30000,
    withCredentials: true,
})

httpClient.defaults.headers = {
    'Cache-Control': 'no-cache, no-store', // should not be required anymore
};

httpClient.interceptors.request.use(config => {
    return config
}, error => {
    return Promise.reject(error)
})

httpClient.interceptors.response.use(response => {
    return Vue.prototype.$parseDates(response.data)
}, error => {
    const toastData = {title: "Error", body: "Unknown", variant: "danger"}
    if (error.response?.status === undefined) {
        toastData.title = "Netzwerkfehler"
        toastData.body = "Es gibt ein Problem mit deiner Netzwerkverbindung."
    } else if ((store.getters["user/current_user"] || null) !== null && error.response?.status === 401) {
        toastData.title = 'dialog.session'
        toastData.body = 'dialog.session_expired'
        store.dispatch("user/resetUser")
    } else if (error.response?.data?.error !== undefined) {
        toastData.title = 'dialog.failed'
        toastData.body = error.response.data.error
    } else if (Vue.i18n.keyExists('dialog.failed')) {
        toastData.title = 'dialog.failed'
        toastData.body = 'dialog.unknown'
    } else if (!Vue.i18n.localeExists(Vue.i18n.locale())) {
        toastData.body = "Failed to download language package for: " + Vue.i18n.locale()
    }
    EventBus.$emit("toast", toastData)
    return Promise.reject(error)
})

// general
export function getLocales() {
    return httpClient({
        method: 'get',
        url: '/public/locales.json',
        headers: {
            'Cache-Control': 'cache',
            'Accept-Language': Vue.i18n.locale()
        }
    })
}

export function getRecords(path, filterQuery = {}, options = {}) {
    if (options['toggleProgressSpinner'] !== false) EventBus.$emit("toggleProgressSpinner", true)
    return httpClient({
        method: 'get',
        url: getPathWithQuery(path, filterQuery),
        headers: {
            'Accept-Language': Vue.i18n.locale()
        }
    }).then(response => {
        if (options['toggleProgressSpinner'] !== false) EventBus.$emit("toggleProgressSpinner", false)
        return Promise.resolve(response)
    }).catch(error => {
        if (options['toggleProgressSpinner'] !== false) EventBus.$emit("toggleProgressSpinner", false)
        return Promise.reject(error)
    })
}

export function getRecord(path, filterQuery, options = {}) {
    return getRecords(path, filterQuery, options)
}

function uploadRecord(path, method, data, options = {}) {
    if (options['toggleProgressSpinner'] !== false) EventBus.$emit("toggleProgressSpinner", true)
    return httpClient({
        method: method,
        url: path,
        headers: {
            'Accept-Language': Vue.i18n.locale()
        },
        data: data
    }).then(response => {
        if (options['toggleProgressSpinner'] !== false) EventBus.$emit("toggleProgressSpinner", false)
        EventBus.$emit('datatableReload')
        let toastConfig = options['toast'] || {}
        // evaluate function
        if (typeof options['toast'] === 'function') {
            toastConfig = options['toast'](response)
        }
        if (toastConfig['useResponseMessage'] === true) {
            toastConfig['title'] ||= ' '
            toastConfig['body'] = response[toastConfig['responseMessageKey'] || 'message']
        }
        if (toastConfig['skip'] !== true) EventBus.$emit("toast", {
            title: toastConfig['title'] || 'dialog.success',
            body: toastConfig['body'] || 'dialog.save_success',
            variant: "success"
        })
        return Promise.resolve(response)
    }).catch(error => {
        if (options['toggleProgressSpinner'] !== false) EventBus.$emit("toggleProgressSpinner", false)
        return Promise.reject(error)
    })
}

export function postRecord(path, data, options = {}) {
    return uploadRecord(path, 'post', data, options)
}

export function putRecord(path, data, options = {}) {
    return uploadRecord(path, 'put', data, options)
}

export function patchRecord(path, data, options = {}) {
    return uploadRecord(path, 'patch', data, options)
}

export function removeRecord(path, id, options = {}) {
    return putRecord(path + id + '/remove.json', null, options)
}
