import Vue from "vue";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
import {BootstrapVue} from "bootstrap-vue"
import vuexI18n from "vuex-i18n"
import {EventBus} from "@/eventbus";
import "@/filters.js"
import "@/icons.js"
import "@/helpers.js"
import "@/websockets.js"

import "bootstrap/dist/css/bootstrap.css"
import "bootstrap-vue/dist/bootstrap-vue.css"
import 'vue-search-select/dist/VueSearchSelect.css'
import "@/styles/style.scss"

Vue.config.productionTip = false;

Vue.use(BootstrapVue)

Vue.use(vuexI18n.plugin, store, {translateFilterName: 't'})

// import locales
const locales = require.context('./locales', true, /\.ya?ml$/i);
locales.keys().forEach(key => {
    Object.keys(locales(key)).forEach(locale => {
        Vue.i18n.add(locale, locales(key)[locale])
    })
});

// set default locale
Vue.i18n.set("de")

new Vue({
    router,
    store,
    render: (h) => h(App),
    created() {
        EventBus.$on("toast", (data) => {
            this.$bvToast.toast(this.$t(data.body || ''), {
                title: this.$t(data.title || ''),
                autoHideDelay: 3000,
                noAutoHide: false,
                variant: data.variant || 'success',
                appendToast: true,
                solid: true,
                noCloseButton: true,
                static: true,
            })
        })
    },
    destroyed() {
        EventBus.$off("toast")
    }
}).$mount("#app");
