<template>
  <div class="file-input input-group drop-area justify-content-center"
       :class="{ dragging: isDragging }"
       @dragenter.prevent="onDragEnter"
       @dragover.prevent="onDragOver"
       @dragleave.prevent="onDragLeave"
       @drop.prevent="onDrop"
       @click="() => { this.$refs[ref].click() }">
    <input :id="id"
           :ref="ref"
           :multiple="$compute(multiple)"
           hidden
           type="file"
           :accept="acceptedFileFormats"
           :disabled="$compute(disabled)"
           @input="() => {}"
           @change="(e) => { this.addFiles(e.target.files) }"/>
    <slot :name="shouldRenderOnDragOver ? 'onDragOver' : 'default'"></slot>
  </div>
</template>

<script>
import {EventBus} from "@/eventbus";

export default {
  name: "FileInput",
  props: {
    id: {
      type: String,
      default: null
    },
    disabled: {
      type: [Boolean, Function],
      default: false
    },
    accept: {
      type: [Array, Function],
      default: () => ["jpeg", "jpg", "png"],
    },
    multiple: {
      type: [Boolean, Function],
      default: false
    },
    maxFileSize: {
      type: [String, Number, Function],
      default: undefined
    }
  },
  data() {
    return {
      ref: Math.random().toString(36).slice(2),
      isDragging: false
    }
  },
  computed: {
    shouldRenderOnDragOver() {
      return this.$scopedSlots.onDragOver && this.isDragging;
    },
    acceptedFileFormats() {
      return this.accept.map(format => "." + format).toString()
    },
    maxFileSizeComputed() {
      let value = this.$compute(this.maxFileSize)
      // parse number
      if (typeof value === 'string') return Number(value)
      return value
    }
  },
  methods: {
    onDragEnter(event) {
      this.isDragging = true;
      this.$emit('onDragEnter', event)
    },
    onDragOver(event) {
      this.isDragging = true;
      this.$emit('onDragOver', event)
    },
    onDragLeave(event) {
      this.isDragging = false;
      this.$emit('onDragLeave', event)
    },
    onDrop(event) {
      this.isDragging = false;
      this.$emit('onDrop', event)
      if (!this.$compute(this.disabled)) this.addFiles(event.dataTransfer.files)
    },
    addFiles(files) {
      if (!files) return;
      ([...files]).forEach(file => {
        // check for max file size
        if (typeof this.maxFileSizeComputed === 'number' && file.size > this.maxFileSizeComputed) {
          this.showErrorDialog(file, this.maxFileSizeErrorMessage(file.size, this.maxFileSizeComputed))
          return
        }
        // check for accepted formats
        if (this.accept.filter(format => file.type.includes(format)).length === 0) {
          this.showErrorDialog(file, this.$t('errors.messages.incorrect_file_format', {formats: this.accept}))
          return
        }
        // emit
        this.$emit('input', file)
        this.$emit('change', file)
      });
    },
    showErrorDialog(file, message) {
      EventBus.$emit("toast", {
        title: 'dialog.error',
        body: file.name + ': ' + message,
        variant: 'danger'
      })
    },
    maxFileSizeErrorMessage(fileSize, maxFileSize) {
      const sizeConfigurations = [
        {value: 1_000_000_000_000.0, label: 'TB'},
        {value: 1_000_000_000.0, label: 'GB'},
        {value: 1_000_000.0, label: 'MB'},
        {value: 1_000.0, label: 'KB'},
        {value: 1.0, label: 'B'}
      ];
      for (const config of sizeConfigurations) {
        if (maxFileSize >= config.value) {
          return this.$t('errors.messages.file_size_too_big', {
            size: (fileSize / config.value).toString() + ' ' + config.label,
            max_size: (maxFileSize / config.value).toString() + ' ' + config.label
          });
        }
      }
      return this.$t('errors.messages.file_size_too_big', {size: fileSize, max_size: maxFileSize})
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.drop-area {
  cursor: pointer;
}
</style>
