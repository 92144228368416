<template>
  <div v-if="showProgressSpinner" class="progress-spinner flex-center align-items-center" @click.prevent>
    <b-spinner/>
  </div>
</template>

<script>
import {EventBus} from "@/eventbus";

export default {
  name: "ProgressSpinner",
  props: {},
  data() {
    return {
      showProgressSpinner: false,
      toggleProgressSpinnerCallback: (shown) => {
        this.showProgressSpinner = shown
      }
    }
  },
  created() {
    EventBus.$on("toggleProgressSpinner", this.toggleProgressSpinnerCallback)
  },
  destroyed() {
    EventBus.$off("toggleProgressSpinner", this.toggleProgressSpinnerCallback)
  },
  computed: {},
  methods: {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.progress-spinner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
}
</style>
