<template>
  <div class="dashboard">
    <accept-gtc-modal v-if="requiresAcceptGTC" :value="true"></accept-gtc-modal>
    <application :sidebar-link-list="allowedSidebarLinkList"></application>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Application from "@/layouts/application.vue";
import AcceptGtcModal from "@/components/accept_gtc_modal.vue";

export default {
  name: "Dashboard",
  components: {AcceptGtcModal, Application},
  data() {
    return {
      sidebarLinkList: [
        {
          name: () => {
            return this.clientName
          },
          style: {
            'margin-left': 0,
            'text-align': 'center',
            color: 'red',
            'text-transform': 'uppercase',
            'margin-bottom': '12px',
            'font-size': '16px'
          },
          active: true,
          meta: {
            permissions: {
              admin: true
            }
          }
        },
        {
          name: () => {
            return this.userFullName
          },
          disabled: true,
          active: true,
          style: {'margin-left': 0, 'text-align': 'center'}
        },
        {
          to: {name: "password"},
          name: "common.password",
          meta: {},
          active: true,
          style: {'margin-left': 0, 'text-align': 'center', width: "100%"}
        },
        {
          to: {name: "dashboard"},
          name: "common.dashboard",
          meta: {},
          style: {marginTop: "40px"}
        },
        {
          to: {name: "prepredict"},
          name: "multiples.prepredict.other",
          meta: {
            permissions: {
              client: {
                rental: true
              }
            }
          }
        },
        {
          to: {name: "transfers"},
          name: "multiples.transfer.other",
          meta: {
            permissions: {
              client: {
                rental: false
              }
            }
          }
        },
        {
          to: {name: "drivers"},
          name: "multiples.driver.other",
          meta: {
            permissions: {
              client: {
                rental: false,
                driver: false
              }
            }
          }
        },
        {
          to: {name: "invoices"},
          name: () => this.invoicesMenuName,
        },
        {
          to: {name: "clients"},
          name: "multiples.client.other",
          style: () => {
            return this.isAdmin ? {marginTop: "40px"} : null
          },
          meta: {
            permissions: {
              admin: true
            }
          }
        },
        /*{ // disabled until updated
          to: {name: "price_lists"},
          name: "multiples.price_list.other",

          meta: {
            permissions: {
              admin: true
            }
          }
        },*/
        {
          to: {name: "managers"},
          name: "multiples.manager.other",
          meta: {
            permissions: {
              admin: true
            }
          }
        },
        {
          to: {name: "dispatchers"},
          name: "multiples.dispatcher.other",
          style: () => {
            return !this.isAdmin ? {marginTop: "40px"} : null
          },
          meta: {
            permissions: {
              manager: true,
              client: {
                driver: false
              }
            }
          }
        }
      ]
    }
  },
  computed: {
    ...mapGetters("user", ["current_user"]),
    year() {
      return new Date().getFullYear()
    },
    allowedSidebarLinkList() {
      return this.filterList(this.sidebarLinkList)
    },
    isAdmin() {
      return this.current_user?.admin
    },
    userFullName() {
      return this.current_user?.full_name || ""
    },
    clientName() {
      return this.current_user?.admin ? this.current_user?.client?.name : null
    },
    invoicesMenuName() {
      return this.current_user?.client?.service ? 'common.revenues' : 'multiples.invoice.other'
    },
    requiresAcceptGTC() {
      return this.current_user?.client?.requires_accept_gtc && this.current_user?.manager && !this.current_user?.admin // admin also has manager rights
    }
  },
  methods: {
    ...mapActions("user", ["resetUser"]),
    filterList(list) {
      const current_user = this.current_user
      return list.filter((t) => {
        return this.$checkPermissions(t.meta?.permissions, current_user)
      })
    }
  }
}
</script>

<style scoped lang="scss">

</style>
