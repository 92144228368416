<template>
  <div class="generic-input">
    <datepicker v-if="type === 'datepicker' || type === 'daypicker' || type === 'timepicker'"
                v-on="$listeners"
                v-bind="$props"
                :id="randomId"/>

    <b-form-group v-else :label-for="randomId">
      <template slot="label" v-if="$compute(title) || ($compute(type)!=='checkbox' && $compute(label))">
        <h5>
          {{ $t($compute(title) || $compute(label)) + (required || $compute(disabled) ? "" : " (Optional)") }}
        </h5>
      </template>

      <b-form-checkbox
          v-if="$compute(type) === 'checkbox'"
          v-model="valueFormatted"
          :id="randomId"
          :disabled="$compute(disabled)">
        <small>{{ $t(placeholder || $compute(label)) }}</small>
      </b-form-checkbox>

      <searchable-select v-else-if="$compute(type) === 'select'"
                         v-on="$listeners"
                         v-bind="$props"
                         :id="randomId"/>

      <number-input v-else-if="$compute(type) === 'number'"
                    v-on="$listeners"
                    v-bind="$props"
                    :id="randomId"
                    :ref="randomId"/>

      <currency-input v-else-if="type === 'currency'"
                      v-on="$listeners"
                      v-bind="$props"
                      :id="randomId"/>

      <file-input v-else-if="type === 'file'"
                  v-on="$listeners"
                  v-bind="$props"
                  :id="randomId">
        <template v-for="(_, name) in $slots" v-slot:[name]="slotData">
          <slot :name="name" v-bind="slotData"/>
        </template>
      </file-input>

      <!-- text, password, email, number, url, tel, search, date, datetime, datetime-local, month, week, time, range, or color -->
      <b-form-input
          v-else
          v-on="$listeners"
          v-bind="$props"
          v-model="valueFormatted"
          :id="randomId"
          :type="$compute(type) || 'text'"
          :placeholder="!$compute(disabled) ? $t(placeholder) : null"
          :invalidMessage="invalidMessage($compute(type))"
          oninvalid="setCustomValidity(attributes?.invalidMessage?.value)"
          oninput="setCustomValidity('')"
          :disabled="$compute(disabled)"/>
    </b-form-group>
  </div>
</template>

<script>
import SearchableSelect from "@/components/searchable_select";
import NumberInput from "@/components/number_input";
import CurrencyInput from "@/components/currency_input";
import Datepicker from "@/components/datepicker";
import FileInput from "@/components/file_input.vue";

export default {
  name: "GenericInput",
  components: {FileInput, Datepicker, CurrencyInput, NumberInput, SearchableSelect},
  props: {
    value: null,
    title: {
      type: [String, Function],
      default: null
    },
    label: {
      type: [String, Array, Function],
      default: null
    },
    placeholder: {
      type: String,
      default: null
    },
    required: {
      type: Boolean,
      default: false
    },
    type: {
      type: [String, Function]
    },
    disabled: {
      type: [Boolean, Function],
      default: false
    },
    state: {
      default: undefined
    },
    fetchRecords: {
      type: [Function, Array],
      default: () => []
    },
    multiple: {
      type: [Boolean, Function],
      default: false
    },
    min: {
      type: [Number, Date],
      default: null
    },
    max: {
      type: [Number, Date],
      default: null
    },
    sign: {
      type: [String, Function],
      default: null
    },
    precision: {
      type: [Number, Function],
      default: 0
    },
    accept: {
      type: [Array, Function],
      default: undefined
    },
    maxFileSize: {
      type: [String, Number, Function],
      default: undefined
    },
    formatter: {
      type: Function,
      default: undefined
    }
  },
  data() {
    return {
      randomId: "input-" + Math.random().toString(36).slice(2)
    }
  },
  watch: {},
  computed: {
    valueFormatted: {
      get: function () {
        return this.value
      },
      set: function (newValue) {
        if (this.formatter !== undefined) {
          this.$emit('input', this.formatter(newValue))
          return
        }
        this.$emit('input', newValue)
      }
    }
  },
  methods: {
    invalidMessage(type) {
      const key = 'validation.' + (type || 'required')
      let translation = this.$t(key)
      return translation !== key ? translation : this.$t('validation.required')
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
