import Vue from "vue";
import {getApiUrl} from "@/services/api";
import {EventBus} from "@/eventbus";

export function getWsUrl() {
    const protocol = getApiUrl().includes("localhost:3000") ? "ws" : "wss"
    return protocol + ":" + new URL(getApiUrl()).host + "/cable" // TODO: enable
}

Vue.prototype.$ws = null

Vue.prototype.$wsConnect = () => {
    if (!getWsUrl() || Vue.prototype.$ws) return
    const websocket = new WebSocket(getWsUrl())
    websocket.onopen = function (event) {
        const channel = "PrologisticsChannel"
        websocket.send(JSON.stringify({command: "subscribe", identifier: JSON.stringify({channel: channel})}))
    }
    websocket.onmessage = (event) => {
        const data = JSON.parse(event.data)
        if (!data.type && data.message) {
            let content = {}
            if (typeof data.message === 'object') {
                content = data.message
            } else if (typeof data.message === 'string') {
                try {
                    content = JSON.parse(data.message)
                } catch {
                    content = {message: data.message}
                }
            }
            EventBus.$emit('websocketMessage', content)
        }
    }
    Vue.prototype.$ws = websocket
}

Vue.prototype.$wsDisconnect = () => {
    if (Vue.prototype.$ws) {
        Vue.prototype.$ws.close()
        Vue.prototype.$ws = null
    }
}